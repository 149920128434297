<div class="page-container">
  <div class="d-none d-lg-block">
    <p-panel styleClass="no-padding">
      <p-table
        [rowHover]="true"
        [columns]="columns"
        [value]="loads"
        #table
        styleClass="p-datatable-no-border"
        [loading]="loading"
        selectionMode="single"
        [paginator]="true"
        [rows]="50"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [first]="first"
        (onPage)="onPage($event)"
        [lazy]="lazy"
        [totalRecords]="totalRecords"
        (onLazyLoad)="onLazyLoad($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'referenceLoadDisplay'">
              Shipper’s Order Number
              <p-sortIcon [field]="'referenceLoadDisplay'"></p-sortIcon>
            </th>
            <th *ngIf="isBookedLoads || isDeliveredLoads" [pSortableColumn]="'billingLoadDisplay'">
              Billing Load ID
              <p-sortIcon [field]="'billingLoadDisplay'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'originCity'">
              Origin
              <p-sortIcon [field]="'originCity'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'destCity'">
              Destination
              <p-sortIcon [field]="'destCity'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'originLateDtTm'">
              Pickup
              <p-sortIcon [field]="'originLateDtTm'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'destLateDtTm'">
              Delivery
              <p-sortIcon [field]="'destLateDtTm'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'equipmentCategoryId'">
              Equipment
              <p-sortIcon [field]="'equipmentCategoryId'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'equipmentTypeDisplay'">
              Equipment Detail
              <p-sortIcon [field]="'equipmentTypeDisplay'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'hasServiceTypes'">
              Required Services
              <p-sortIcon [field]="'hasServiceTypes'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'hasPod'" *ngIf="!isMarketplace">
              Received POD
              <p-sortIcon [field]="'hasPod'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'miles'" style="width: 125px">
              Distance (Mi)
              <p-sortIcon [field]="'miles'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'totalRateDisplay'">
              Total Rate
              <p-sortIcon [field]="'totalRateDisplay'"></p-sortIcon>
            </th>
            <th *ngIf="isBookedLoads || isDeliveredLoads" [pSortableColumn]="'scac'" style="width: 90px">
              SCAC
              <p-sortIcon [field]="'scac'"></p-sortIcon>
            </th>
            <th *ngIf="isBookedLoads" [pSortableColumn]="'currentStatus'">
              Status
              <p-sortIcon [field]="'currentStatus'"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-loads let-rowIndex="rowIndex">
          <tr (click)="onRowSelect(loads)" [pSelectableRow]="loads">
            <td>
              <kbxl-customer-type-icon
                [referenceLoadDisplay]="loads.referenceLoadDisplay"
                [customerLoadTypeId]="loads.customerLoadTypeId"
                [isBrokeredLoad]="loads.isBrokeredLoad"
              ></kbxl-customer-type-icon>
            </td>
            <td [hidden]="!isBookedLoads && !isDeliveredLoads">
              {{ loads.billingLoadDisplay }}
            </td>
            <td>{{ loads.originCity | titlecase }}, {{ loads.originState }}</td>
            <td>{{ loads.destCity | titlecase }}, {{ loads.destState }}</td>
            <td>
              <span *ngIf="loads.originEarlyDtTm && loads.originEarlyDtTm !== loads.originLateDtTm">
                {{ loads.originEarlyDtTm | date: 'short' }}
                <br />{{ loads.originLateDtTm | date: 'short' }}
              </span>
              <span *ngIf="!loads.originEarlyDtTm || loads.originEarlyDtTm === loads.originLateDtTm">
                {{ loads.originLateDtTm | date: 'short' }}
              </span>
              <div class="eta-text" *ngIf="isBookedLoads">
                {{ getApptEstLabel(loads, true, false) }}
              </div>
              <div class="eta-text" *ngIf="isBookedLoads">
                {{ getApptEstLabel(loads, true, true) }}
              </div>
            </td>
            <td>
              <span *ngIf="loads.destEarlyDtTm && loads.destEarlyDtTm !== loads.destLateDtTm">
                {{ loads.destEarlyDtTm | date: 'short' }}
                <br />{{ loads.destLateDtTm | date: 'short' }}
              </span>
              <span *ngIf="!loads.destEarlyDtTm || loads.destEarlyDtTm === loads.destLateDtTm">
                {{ loads.destLateDtTm | date: 'short' }}
              </span>
              <div class="eta-text" *ngIf="isBookedLoads">
                {{ getApptEstLabel(loads, false, false) }}
              </div>
              <div class="eta-text" *ngIf="isBookedLoads">
                {{ getApptEstLabel(loads, false, true) }}
              </div>
            </td>
            <td>
              {{ loads.equipmentCategoryId }}
            </td>
            <td>
              {{ loads.equipmentTypeDisplay }}
            </td>
            <td
              (mouseenter)="serviceTypeMouseHover(loads, serviceTypesOverlay, $event)"
              (mouseleave)="serviceTypeMouseHoverOut(serviceTypesOverlay)"
            >
              <span *ngFor="let serviceType of getServiceTypes(loads)" [ngClass]="serviceType.elementClasses">
                {{ serviceType.name }}
              </span>
            </td>
            <td class="text-center" *ngIf="!isMarketplace">
              <button
                type="button"
                *ngIf="loads.hasPod"
                class="p-button-outlined p-button-secondary ui-grid-button"
                pButton
                [label]="getPodRecievedDisplay(loads)"
                (click)="showDocumentOverlay(loads, loadDocumentOverlay, $event)"
              ></button>
              <span *ngIf="!loads.hasPod">{{ getPodRecievedDisplay(loads) }}</span>
            </td>
            <td class="text-right">
              {{ loads.miles }}
            </td>
            <td class="text-right" (mouseenter)="showLinehaulRateOverlay($event, loads)" (mouseleave)="hideLinehaulRateOverlay()">
              <fa-icon *ngIf="loads.isEstimatedFSC" [icon]="['fas', 'asterisk']" class="estimated-fsc-icon orange"></fa-icon>
              <fa-icon
                *ngIf="loads.hasDynamicPrice"
                class="dynamic-pricing"
                title="Dynamic Pricing Enabled"
                [icon]="['fas', 'binoculars']"
                size="md"
              ></fa-icon>              
              {{loads.totalRateDisplay | currency}}
              <!-- {{ loads.lineHaulRate + loads.fuelRate | currency }} -->
            </td>
            <td [hidden]="!isBookedLoads && !isDeliveredLoads">
              {{ loads.scac }}
            </td>
            <td [hidden]="!isBookedLoads">
              {{ loads.currentStatus }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="numberOfColumns" style="text-align: center; height: 80px">
              <span *ngIf="loading">Loading...</span>
              <span *ngIf="!loading">No results</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-panel>
  </div>
  <div class="d-lg-none">
    <div class="loading-indicator" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <h4 *ngIf="!loading && loads?.length == 0" class="p-4 text-center">No results</h4>
    <div class="load-item d-flex flex-column" *ngFor="let load of loads; let i = index">
      <a (click)="onRowSelect(load)">
        <p-card styleClass="load-grid-card">
          <p-header>
            <div class="d-flex flex-column">
              <div class="d-flex flex-wrap align-items-center">
                <div class="flex-grow-1">
                  <div class="trip-origin">
                    <div class="trip-marker-icon">
                      <fa-icon [icon]="['far', 'circle']" size="md"></fa-icon>
                    </div>
                    <span class="trip-text">{{ load.originCity | titlecase }}, {{ load.originState }}</span>
                  </div>
                  <div class="trip-ellipsis-icon">
                    <fa-icon [icon]="['fas', 'ellipsis-v']" size="md"></fa-icon>
                  </div>
                  <div class="trip-destination">
                    <div class="trip-marker-icon">
                      <fa-icon [icon]="['fas', 'map-marker-alt']" size="md"></fa-icon>
                    </div>
                    <span class="trip-text">{{ load.destCity | titlecase }}, {{ load.destState }}</span>
                  </div>
                </div>
                <div>
                  <!-- <p class="all-in-rate-value">{{ load.lineHaulRate + load.fuelRate | currency }}</p> -->
                  <p class="all-in-rate-value">{{loads.totalRateDisplay | currency}}</p>
                  <p class="all-in-rate-label text-right">Total Rate</p>
                </div>
              </div>
            </div>
          </p-header>
          <div class="d-flex flex-column load-item-detail">
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Shipper’s Order Number</span>
              </div>
              <div>
                <span>{{ load.referenceLoadDisplay }}</span>
              </div>
            </div>
            <div *ngIf="isBookedLoads || isDeliveredLoads" class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Billing Load ID</span>
              </div>
              <div>
                <span>{{ load.billingLoadDisplay }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Pickup</span>
              </div>
              <div>
                <span *ngIf="load.originEarlyDtTm && load.originEarlyDtTm !== load.originLateDtTm">
                  {{ load.originEarlyDtTm | date: 'short' }} - {{ load.originLateDtTm | date: 'short' }}
                </span>
                <span *ngIf="!load.originEarlyDtTm || load.originEarlyDtTm === load.originLateDtTm">
                  {{ load.originLateDtTm | date: 'short' }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Delivery</span>
              </div>
              <div>
                <span *ngIf="load.destEarlyDtTm && load.destEarlyDtTm !== load.destLateDtTm">
                  {{ load.destEarlyDtTm | date: 'short' }} - {{ load.destLateDtTm | date: 'short' }}
                </span>
                <span *ngIf="!load.destEarlyDtTm || load.destEarlyDtTm === load.destLateDtTm">
                  {{ load.destLateDtTm | date: 'short' }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Distance</span>
              </div>
              <div>
                <span>{{ load.miles }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Equipment</span>
              </div>
              <div>
                <span>{{ load.equipmentCategoryId }} - {{ load.equipmentTypeDisplay }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center" *ngIf="load.serviceTypeIds?.length > 0">
              <div class="flex-grow-1">
                <span>Required Services</span>
              </div>
              <div>
                <span>{{ getServiceTypeNames(load) }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center" *ngIf="!isMarketplace">
              <div class="flex-grow-1">
                <span>Recieved POD</span>
              </div>
              <div>
                <span>{{ getPodRecievedDisplay(load) }}</span>
              </div>
            </div>
            <div *ngIf="isBookedLoads || isDeliveredLoads" class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>SCAC</span>
              </div>
              <div>
                <span>{{ load.scac }}</span>
              </div>
            </div>
            <div *ngIf="!isMarketplace && load.hasPod" class="d-flex align-items-center">
              <div class="flex-grow-1 py-2">
                <div class="d-flex justify-content-between">
                  <button
                    type="button"
                    class="p-button-outlined p-button-secondary ui-grid-button"
                    pButton
                    [label]="'View POD Documents'"
                    (click)="showDocumentDialog(load, $event)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </a>
    </div>
  </div>
</div>

<kbxl-linehaul-rate-overlay
  [linehaulRateData]="linehaulRateData"
  [showLinehaulOverlay]="showLinehaulOverlay"
  [event]="showLinehaulOverlayEvent"
></kbxl-linehaul-rate-overlay>

<kbxl-rate-break-downs-overlay
  [rateBreakDownInput]="rateBreakDownInput"
  [showRateBreakDownOverlay]="showrateBreakDownOverlay"
  [event]="showrateBreakDownOverlayEvent"
></kbxl-rate-break-downs-overlay>

<p-overlayPanel #serviceTypesOverlay showTransitionOptions="0ms" hideTransitionOptions="0ms">
  <div *ngIf="hoveredPricingRow">
    {{ getServiceTypesHover(hoveredPricingRow) }}
  </div>
</p-overlayPanel>
<p-overlayPanel #loadDocumentOverlay [showCloseIcon]="true" howTransitionOptions="0ms" hideTransitionOptions="0ms">
  <ng-template pTemplate>
    <kbxl-document-list
      [documents]="selectedLoadDocumentsLoad?.podDocuments"
      [loadId]="selectedLoadDocumentsLoad?.loadId"
      (documentChange)="handleDocumentChange()"
      [allowOtherDocumentType]="false"
    ></kbxl-document-list>
  </ng-template>
</p-overlayPanel>
<p-dialog #loadDocumentDialog transitionOptions="0ms" [(visible)]="documentDialogVisible" modal="true">
  <div class="p-1">
    <kbxl-document-list
      [documents]="selectedLoadDocumentsLoad?.podDocuments"
      [loadId]="selectedLoadDocumentsLoad?.loadId"
      (documentChange)="handleDocumentChange()"
      [allowOtherDocumentType]="false"
    ></kbxl-document-list>
  </div>
</p-dialog>
