import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChatState } from 'src/app/chat/store/reducers';
import { CoreState, CustomerLoadAction, getCustomer, LoadStatusLoadAction } from 'src/app/core/store';
import { getLoadStatusDetail, getLoadStatusLoading } from 'src/app/core/store/selectors/load-status.selector';
import { CreateDirectMessageData } from '../../../chat/models';
import { JoinDirectMessageAction } from '../../../chat/store/actions';
import { getLoadBoardShowAllLoadsContext, LoadBoardState } from '../../../load-board/store';
import {
  getRatingQuestions,
  getShippingLoadAuditLogForLoadId,
  getShippingLoadLoadingAuditLogForLoadId,
  RatingGetQuestionsAction,
  ShippingLoadAuditLogsLoadAction,
  ShippingLoadDetailDeleteLoadAction,
  ShippingState,
} from '../../../shipping/store';
import { getUserProfileEntity, getUserProfileModel, UserState } from '../../../user/store';
import {
  AuditType,
  BookLoadRequest,
  Customer,
  ILoadAuditLogData,
  Load,
  LoadAudit,
  LoadDetail,
  LoadStatusDetail,
  LoadStatusTypes,
  RatingQuestion,
  RemoveCarrierData,
  RemoveLoadData,
  ResponseError,
  TransactionType,
  UserModel,
} from '../../models';
import { SecurityAppActionType } from '../../models/security-app-action-type';
import {
  getLoadBoardBooking,
  getLoadBoardLoadDetailErrors,
  getLoadBoardSelectedLoad,
  getLoadDocumentLoading,
  getUnlockSaving,
  LoadBoardLoadAuditAction,
  LoadBoardLoadBookAction,
  LoadBoardLoadDetailClosed,
  LoadBoardLoadDetailLoadAction,
  LoadDetailCarrierRemovedAction,
  LoadDetailDeleteLoadAction,
  SharedState,
} from '../../store';
import { BaseComponent } from '../base-component';
import { ShippingLoadAuditLogGridModalComponent } from '../shipping-load-audit-log-grid-modal/shipping-load-audit-log-grid-modal.component';

@Component({
  selector: 'kbxl-load-detail-container',
  templateUrl: './load-detail-container.component.html',
  styleUrls: ['./load-detail-container.component.scss'],
  providers: [DialogService],
})
export class LoadDetailContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  modal = true;

  loadDetail$: Observable<LoadDetail>;
  ratingQuestions$: Observable<RatingQuestion[]>;
  loadStatus$: Observable<LoadStatusDetail>;
  loadingStatus$: Observable<boolean>;
  userProfile$: Observable<UserModel>;
  loadDetailErrors$: Observable<ResponseError[]>;
  processing$: Observable<boolean>;
  shipper$: Observable<Customer>;
  showRateBreakDowns$: Observable<boolean>;
  displayInSidebar: boolean;
  isShippingDetail: boolean;
  isChatDetail: boolean;
  captureReasonOnDelete = false;
  displayMarginalAnalysisDialog = false;
  wasLoadAuditActionAlreadyTriggered = false;
  private auditType: AuditType;
  private _detail: LoadDetail;
  public isReadOnly = false;
  loadAuditLogs$: Observable<ILoadAuditLogData[]>;
  loadingAuditLogs$: Observable<boolean>;
  loadingAuditLogs = false;

  loadId: string;
  navigateOnClose = true;
  fetchedCustomerId: string;
  constructor(
    private store: Store<SharedState>,
    private shippingStore: Store<ShippingState>,
    private route: ActivatedRoute,
    private router: Router,
    private userStore: Store<UserState>,
    private coreStore: Store<CoreState>,
    private loadBoardStore: Store<LoadBoardState>,
    private dialogService: DialogService,
    private chatStore: Store<ChatState>
  ) {
    super();
  }

  ngOnInit() {
    if (this.route.snapshot.data) {
      this.displayInSidebar = this.route.snapshot.data.sidebar === true;
      this.captureReasonOnDelete = this.route.snapshot.data.captureReasonOnDelete === true;
      this.isReadOnly = this.route.snapshot.data.readOnly === true;

      if (this.route.snapshot.data.modal !== undefined) {
        this.modal = this.route.snapshot.data.modal;
      }
    }
    this.setDetailPageContext();

    this.userStore.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$)).subscribe((user) => {
      if (user && user.isShipper) {
        this.shippingStore.dispatch(new RatingGetQuestionsAction());
      }
    });
    this.route.params
      .pipe(
        filter((p) => p.id),
        map((params) => params.id),
        takeUntil(this.destroyed$)
      )
      .subscribe((id) => {
        this.loadId = id;
        this.store.dispatch(new LoadBoardLoadDetailLoadAction(id));
        this.route.queryParams
          .pipe(
            filter((p) => p.at),
            takeUntil(this.destroyed$)
          )
          .subscribe((params) => {
            if (params.at) {
              this.auditType = (params.at as keyof typeof AuditType) as AuditType;
            }
          });
      });

    this.loadDetail$ = this.store.pipe(
      select(getLoadBoardSelectedLoad),
      map((_) => this.deepClone(_)),
      tap((detail) => {
        this._detail = detail;
        this.triggerLoadStatusLoadAction();
        this.triggerLoadAuditAction();
        this.triggerShippingLoadAuditLogsLoadAction();
        if (this._detail && this._detail.customerId && this.fetchedCustomerId !== this._detail.customerId) {
          this.fetchedCustomerId = this._detail.customerId;
          this.coreStore.dispatch(new CustomerLoadAction({ customerId: this._detail.customerId })); // Get load's shipper for DM purposes
        }
      })
    );

    this.loadStatus$ = this.loadDetail$.pipe(
      switchMap((detail) => {
        const isPlatformLoad = detail ? detail.isPlatformLoad : false;
        return isPlatformLoad ? of(this.mapPlatformLoadStatusDetails(detail)) : this.coreStore.pipe(select(getLoadStatusDetail));
      })
    );

    this.ratingQuestions$ = this.shippingStore.pipe(select(getRatingQuestions));
    this.loadingStatus$ = this.coreStore.pipe(select(getLoadStatusLoading));
    this.userProfile$ = this.coreStore.pipe(select(getUserProfileModel));
    this.loadDetailErrors$ = this.store.pipe(select(getLoadBoardLoadDetailErrors));
    this.showRateBreakDowns$ = this.loadBoardStore.pipe(
      select(getLoadBoardShowAllLoadsContext),
      map((x) => (x ? true : false))
    );

    this.processing$ = combineLatest([
      this.store.pipe(select(getLoadBoardBooking)),
      this.store.pipe(select(getLoadDocumentLoading)),
      this.store.pipe(select(getUnlockSaving)),
    ]).pipe(map((args) => args[0] || args[1] || args[2]));

    this.shipper$ = this.coreStore.pipe(select(getCustomer));
  }

  private mapPlatformLoadStatusDetails(loadDetail: LoadDetail): LoadStatusDetail {
    if (!loadDetail.loadCurrentStatus) {
      return null;
    }

    let loadStatus: LoadStatusTypes = null;

    switch (loadDetail.loadCurrentStatus.status.toLowerCase()) {
      case 'in transit':
        loadStatus = LoadStatusTypes.InTransit;
        break;
      case 'delivered':
        loadStatus = LoadStatusTypes.Delivered;
        break;
    }

    return {
      processingUpdates: false,
      stopNumber: loadDetail.loadCurrentStatus.stopNumber,
      description: loadDetail.loadCurrentStatus.description,
      status: loadStatus,
      dateLabel: loadDetail.loadCurrentStatus.dateLabel,
      locationLabel: loadDetail.loadCurrentStatus.locationLabel,
    };
  }

  setDetailPageContext() {
    if (this.route.pathFromRoot && this.route.pathFromRoot.length > 1 && this.route.pathFromRoot[1].url) {
      this.route.pathFromRoot[1].url.pipe(take(1), takeUntil(this.destroyed$)).subscribe((segments) => {
        if (segments && segments.length > 0) {
          this.isShippingDetail = segments[0].path && segments[0].path.toLowerCase() === 'shipping';
          this.isChatDetail = segments[0].path && segments[0].path.toLowerCase() === 'chat';
        }
      });
    }
  }

  book(request: BookLoadRequest) {
    this.store.dispatch(new LoadBoardLoadBookAction(request));
    // Do not hide as we need to verify the load was booked before closing
    // this.onHide();
  }

  removeCarrier(payload: RemoveCarrierData) {
    this.store.dispatch(new LoadDetailCarrierRemovedAction(payload));
    this.onHide();
  }

  deleteLoad(payload: string) {
    this.store.dispatch(new ShippingLoadDetailDeleteLoadAction(payload));
    this.onHide();
  }

  deleteDetailLoad(payload: RemoveLoadData) {
    this.store.dispatch(new LoadDetailDeleteLoadAction(payload));
    this.onHide();
  }

  onHide() {
    if (this.navigateOnClose) {
      // navigate up two segments from our detail/:id path
      this.router.navigate(['../..'], { relativeTo: this.route });
    }

    this.store.dispatch(new LoadBoardLoadDetailClosed(this.loadId));
  }

  private triggerLoadStatusLoadAction() {
    const detail = this._detail;
    if (
      detail &&
      !detail.isPlatformLoad &&
      detail.isPlatformPlus &&
      this.isShippingDetail &&
      detail.loadTransaction &&
      (detail.loadTransaction.transactionType === TransactionType.Accepted ||
        detail.loadTransaction.transactionType === TransactionType.Delivered ||
        detail.loadTransaction.transactionType === TransactionType.PendingPod)
    ) {
      this.store.dispatch(
        new LoadStatusLoadAction({
          loadId: detail.loadId,
          referenceLoadId: detail.billingLoadId || detail.platformPlusLoadId || detail.referenceLoadId,
        })
      );
    }
  }

  private triggerLoadAuditAction() {
    if (!this.auditType || !this._detail) {
      return;
    }

    if (!this.wasLoadAuditActionAlreadyTriggered) {
      this.wasLoadAuditActionAlreadyTriggered = true;

      const adjustedRate = this._detail.lineHaulRate + this._detail.fuelRate;

      switch (this.auditType) {
        /**
         * Because the detail container is now routeable and loaded via URL, we have to pass in query params
         * for all views that we want to track.
         */
        case AuditType.MarketplaceView:
        case AuditType.MarketplaceFavoriteView:
        case AuditType.FavoritesMatchEmailView:
        case AuditType.ReadyToBookEmailView:
          this.store.dispatch(new LoadBoardLoadAuditAction(new LoadAudit(this._detail.loadId, this.auditType, adjustedRate)));
          break;
        default:
          break; // Do not audit anything, just assume the param being passed is bad
      }
    }
  }

  loadCurrentStatus() {
    this.triggerLoadStatusLoadAction();
  }

  showMarginalAnalysis(load: Load): void {
    this.displayMarginalAnalysisDialog = true;
  }

  private triggerShippingLoadAuditLogsLoadAction() {
    if (!this._detail) {
      return;
    }
    const detail = this._detail;
    this.userProfile$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (!this.loadingAuditLogs && detail && detail.loadId && user && user.hasSecurityAction(SecurityAppActionType.LoadCarrierViews)) {
        this.shippingStore.dispatch(new ShippingLoadAuditLogsLoadAction({ loadId: detail.loadId }));
        this.loadingAuditLogs = true;
        this.setLoadAuditLogs();
      }
    });
  }

  private setLoadAuditLogs() {
    if (!this._detail) {
      return;
    }
    const detail = this._detail;
    this.loadAuditLogs$ = this.shippingStore.pipe(
      select(getShippingLoadAuditLogForLoadId, { loadId: detail.loadId }),
      map((x) => this.deepClone(x))
    );
    this.loadingAuditLogs$ = this.shippingStore.pipe(select(getShippingLoadLoadingAuditLogForLoadId, { loadId: detail.loadId }));
  }

  viewCarrierClicks() {
    const currentZindex = Number(
      window.document.defaultView.getComputedStyle(document.getElementsByClassName('detail-sidebar')[0]).getPropertyValue('z-index')
    );
    this.dialogService.open(ShippingLoadAuditLogGridModalComponent, {
      data: {
        loadAuditLogs: this.loadAuditLogs$,
        loadingAuditLogs: this.loadingAuditLogs$,
      },
      style: { 'max-width': 'calc(100vw - 200px)', 'min-width': 'fit-content', 'max-height': '70%' },
      header: 'Carrier Views',
      closable: true,
      closeOnEscape: true,
      baseZIndex: currentZindex + 1,
    });
  }

  chatNow(dmData: CreateDirectMessageData): void {
    // Need to potentially trigger loading flag on side card
    this.chatStore.dispatch(new JoinDirectMessageAction(dmData));
    // we don't want this component to trigger navigation since the above action will select the new conversation to join
    this.navigateOnClose = false;
  }
}
