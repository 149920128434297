import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CoreState, getLoadDocumentTypes } from '../../../../core/store';
import { LoadDocumentMetadata, LoadDocumentType } from '../../../../shared/models';
import { LoadDocumentDeleteDocumentAction, SharedState } from '../../../store';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class DocumentListComponent extends BaseComponent implements OnChanges {
  _documents: LoadDocumentMetadata[];
  @Input() set documents(value: LoadDocumentMetadata[]) {
    if (value) {
      this._documents = value.map((x) => this.deepClone(x));
    }
  }
  @Input() loadId: string;
  @Input() allowOtherDocumentType = true;
  @Input() isReadOnly = false;
  @Input() basicFileSelect = false;
  @Input() customDocumentUpload = false;
  @Output() documentChange = new EventEmitter<boolean>();

  get documents(): LoadDocumentMetadata[] {
    return this._documents;
  }

  loadDocumentTypes$: Observable<LoadDocumentType[]>;
  loadDocumentTypesSub: Subscription;
  fileUploadModalRef: DynamicDialogRef;
  showUploadDocumentModal = false;
  constructor(private store: Store<SharedState>, private coreStore: Store<CoreState>, private confirmationService: ConfirmationService) {
    super();
    this.loadDocumentTypes$ = of([]);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadDocumentTypes$ = this.coreStore.pipe(
      select(getLoadDocumentTypes),
      switchMap((x) => {
        if (!this.allowOtherDocumentType) {
          return of(x.filter((y) => y.description.toLowerCase() !== 'other'));
        }
        return of(x);
      })
    );
  }

  deleteDocument(doc: LoadDocumentMetadata): void {
    this.confirmationService.confirm({
      header: 'Remove Document',
      message: 'Are you sure you want to delete this document?',
      accept: () => {
        this.store.dispatch(new LoadDocumentDeleteDocumentAction(doc));
        const index = this.documents.indexOf(doc);
        this.documents.splice(index, 1);
      },
    });
  }
  isDocumentReadOnly(doc: LoadDocumentMetadata): boolean {
    switch (doc?.loadDocumentType?.description?.toLowerCase()) {
      case 'accessorial':
      case 'rate confirmation':
        return true;
      default:
        return this.isReadOnly;
    }
  }
  addDocument(): void {
    this.showUploadDocumentModal = true;
  }

  uploadModalClosed(closeArgs: boolean) {
    if (!this.customDocumentUpload && closeArgs) {
      this.showUploadDocumentModal = false;
      // handle file upload success
      this.documentChange.emit(true);
    }
  }
}
