export interface Customer {
  customerId: string;
  name: string;
  isChatEnabled: boolean;
  brokeredLoads: boolean;
  isPlatform: boolean;
}

export const defaultCustomer: Customer = {
  customerId: null,
  name: null,
  isChatEnabled: false,
  brokeredLoads: false,
  isPlatform: false,
};
